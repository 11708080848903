:root {
  --back-color: #f5f5f5;
  --primary-color: #004C97;
}



.App {
  text-align: center;
}

body {
  background-color: #fff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*Scroll Style */
body::-webkit-scrollbar {
  width: 8px;
  /* Tamaño del scroll en vertical */
  height: 8px;
  /* Tamaño del scroll en horizontal */
}

body::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
body::-webkit-scrollbar-thumb:hover {
  background: #004C97;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
body::-webkit-scrollbar-thumb:active {
  background-color: #004C97;
}

/* Ponemos un color de fondo y redondeamos las esquinas del track */
body::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

/*  */
/*Scroll Style */
.tbl-scroll::-webkit-scrollbar {
  width: 8px;
  /* Tamaño del scroll en vertical */
  height: 8px;
  /* Tamaño del scroll en horizontal */
}

.tbl-scroll::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.tbl-scroll::-webkit-scrollbar-thumb:hover {
  background: #004C97;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.tbl-scroll::-webkit-scrollbar-thumb:active {
  background-color: #004C97;
}

/* Ponemos un color de fondo y redondeamos las esquinas del track */
.tbl-scroll::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

/* End Scroll Style */

/*  */
/*Scroll Style */
.tbl-scroll-mn::-webkit-scrollbar {
  width: 5px;
  /* Tamaño del scroll en vertical */
  height: 5px;
  /* Tamaño del scroll en horizontal */
}

.tbl-scroll-mn::-webkit-scrollbar-thumb {
  background: #AAB7CF;
  /* border-radius: 4px; */
}


/* End Scroll Style */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Custome */
.icon-fill {
  color: white;
}

.icon {
  color: #004C97 !important;
}

.icon-dark {
  color: #000 !important;
}

.icon-white {
  color: #FFF !important;
}

.icon-green {
  color: #47a066;
}

.icon-gray {
  color: #BDBDBD;
}

.rounded-circle {
  width: 36px;
  height: 36px;
  background: white;
  color: #004C97;
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge-number {
  background: red;
}

.nav-link.collapsed.active {
  background-color: #e8eaf6 !important;
  border-left: 3.5px solid #004C97;
}

.btn.btn-primary.active {
  background: #e8eaf6 !important;
  color: #004C97 !important;
  /* border-left: 3.5px solid #004C97; */
}

.toggle-sidebar-btn {
  display: none;
}

@media (max-width: 1200px) {
  .toggle-sidebar-btn {
    display: block;
  }
}

@media (max-width: 1200px) {
  .nav-menu {
    display: none;
  }
}

select.form-select {
  border: none;
  background: #f5f5f5;
  height: 36px;
  border-radius: 5px;
}

.btn-primary {
  background: none !important;
  color: white !important;
}

.btn-primary:hover {
  background: #e8eaf6 !important;
  color: #004C97 !important;
}

body tr {
  font-size: 14px;
}

.btn-addon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9ecef;
  border-radius: 0rem 0.25rem 0.25rem 0rem;
  border: none;
  box-shadow: none;
  width: 2.5rem;
}

.btn.btn-outline {
  background-color: white;
  border: 1px solid #004C97;
  color: #000;
}

.btn.btn-light {
  background-color: #f5f5f5;
  color: #000;
}

.btn.btn-outline:hover {
  background-color: white;
  border: 1px solid #004C97;
  color: #000;
}

.heder-subtitle {
  height: 40px;
}

.status-icon {
  border-radius: 50px;
  /* background-color: red; */
  width: 10px;
  height: 10px;
}

/* checkboxbutton */

/* This is to be able to center the content in the middle of the page; */
/* .container{width: 250px; height: 38px; margin: 100px auto; */

/* div label input {margin-right: 100px;} */
.container-buttons {
  width: 450px;
  padding: 20px;
}

.cat {
  margin: 4px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #004C97;
  overflow: hidden;
  float: left;
}

.cat label {
  float: left;
  line-height: 3.0em;
  width: 2em;
  height: 2em;
}

.cat label span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
  ;
}

.cat label input {
  position: absolute;
  display: none;
  color: #fff !important;
}

/* selects all of the text within the input element and changes the color of the text */
.cat label input+span {
  color: #000;
}


/* This will declare how a selected input will look giving generic properties */
.cat input:checked+span {
  color: #ffffff;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
}


/*
This following statements selects each category individually that contains an input element that is a checkbox and is checked (or selected) and chabges the background color of the span element.
*/

.action input:checked+span {
  background-color: #004C97;
}

.comedy input:checked+span {
  background-color: #004C97;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {

  border-color: transparent transparent #004C97 transparent;
}

.nav-link.tab-active {
  border-bottom: 2px solid #004C97;
}

.error {
  /* position: absolute; */
  color: red;
  font-size: .8rem;
}

/* select  */
.css-13cymwt-control {
  background: #f5f5f5 !important;
  border: none !important;
}

.btn-add {
  width: 35px;
  height: 35px;
  background-color: #e8eaf6;
  color: #004C97;
}

/* select-cust */
.select-custome {
  border: 1px solid #e8eaf6;
  height: 35px;
  width: 220px;
}

.headerTab {
  box-shadow: 0px 6px 5px 1px rgba(191, 183, 183, 0.39);
}

/* etapas */
.group-containers {
  border: 2px solid #e8eaf6;
  border-radius: 5px;
}

.count_etapas {
  background-color: #f5f5f5;
  padding: 4px 6px;
  border-radius: 5px;
}

.btn-etapa {
  border-style: none;
  background-color: #004C97;
  border-radius: 5px;
  color: white;
}

.items-etapa {
  border-bottom: 2px solid #e8eaf6;
}

.top {
  background: #e8eaf6;
  width: 2px;
  height: 100%;
}

/* dropdown */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  top: 33px;
  z-index: 1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
  display: flex;
  flex-direction: column;
}

.dropdown-content:after {
  position: absolute;
  left: 50%;
  top: -10px;
  width: 0;
  height: 0;
  content: '';
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #f1f1f1;
}

.dropdown-content-tbl {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  top: 33px;
  left: -60px;
  z-index: 1;
  margin-bottom: 30px;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content-tbl {
  display: block;
  display: flex;
  flex-direction: column;
}

.dropdown-content-tbl:after {
  position: absolute;
  left: 35%;
  top: -10px;
  width: 0;
  height: 0;
  content: '';
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid white;
}

.sub-sidebar {
  /* width: 15rem; */
  border-right: 2px solid rgb(224, 224, 224);
}

.custome-progress {
  border-radius: 50px !important;
  height: .7rem;
  background-color: white;
}

.active-menu {
  background-color: #e8eaf6 !important;
  color: #000 !important;
  border-radius: 0px !important;
}

.listDocs {
  background-color: #FFF !important;
  color: #000 !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #dee2e6 !important
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #0C9;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

#react-doc-viewer>#header-bar {
  display: none !important;
}

.visorDatadocs
{
  height: 100% !important;
}
.w-watermark-wrapper
{
  height: 100% !important;
}

#pdf-download {
  display: none;
}

.warnin-toast {
  background-color: #f0bf52;
  border-radius: 5px;
  color: white;
  padding: 5px 20px;
  border-left: 10px #f4ad11 solid;
}

#pdf-toggle-pagination {
  display: none;
}

.w-watermark {
  /* z-index: 1 !important; */
  position: absolute !important;
  left: 20% !important;
  top: 20% !important;
  /* width: auto !important;
  height: auto !important;  */
  background-size: 800px !important;
  opacity: 0.5;
  /* background-color: red; */
  pointer-events: none;
  background-repeat: no-repeat !important;
  height: 100% !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

#lbl_Seguros_Sidebar {
  margin-left: 11px;
}

#imgLogotipo {
  margin-right: 12px;
  margin-left: 3px;
}

#lbl_Inventario_Sidebar {
  margin-left: 11px;
}

#lbl_Panel_Sidebar {
  margin-left: 11px;
}

.section-clave {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: #E8EAF6;
  border-radius: 3px;
  margin-left: 2px;
  color: #004C97
}

.table-responsive .dropdown,
.btn-group-vertical {
  position: absolute;
}

/* tableSticky */
.stycky {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  z-index: 2;
  background: #F2F2F2 !important;
}

.w-watermark-wrapper {
  height: 100vh !important;
  word-wrap: break-word;
}